<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }" @click.self="closeModel">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <h1>Rewards Notifications</h1>
                <button class="close_btn left_out" @click="closeModel"><i class="fas fa-long-arrow-alt-right"></i></button>
                <ul class="tab_sec justify-content-start">
                    <li @click="tab = 'pointsAwarded'" :class="{active: tab === 'pointsAwarded'}">Points Awarded</li>
                    <li @click="tab = 'pointsRedeem'"  :class="{active: tab === 'pointsRedeem'}">Points Redeemed</li>
                </ul>
            </div>
            <div class="modal_body">
                <Form v-slot="{ errors }" ref="notificationForm">
                    <div v-show="tab === 'pointsAwarded'">
                        <div class="section_wpr">
                            <div class="edit_section">
                                <label for="awarded_status" class="switch_option capsule_btn">
                                    <h5>Notification</h5>
                                    <input type="checkbox" id="awarded_status" v-model="form.awarded_status" :true-value="1" :false-value="0" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                        </div>
                        <div v-show="form.awarded_status == 1">
                            <div class="section_wpr mb-2">
                                <h3>Sending Method</h3>
                            </div>
                            <notification-setting v-if="loadNotification" :errors="errors" v-model="awardedForm" :type="'awardedForm'" ref="awarded-form" />
                        </div>
                    </div>
                    <div v-show="tab === 'pointsRedeem'">
                        <div class="section_wpr">
                            <div class="edit_section">
                                <label for="redeemed_status" class="switch_option capsule_btn">
                                    <h5>Notification</h5>
                                    <input type="checkbox" id="redeemed_status" v-model="form.redeemed_status" :true-value="1" :false-value="0" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                        </div>
                        <div v-show="form.redeemed_status == 1">
                            <div class="section_wpr mb-2">
                                <h3>Sending Method</h3>
                            </div>
                            <notification-setting v-if="loadNotification" :errors="errors" v-model="redeemedForm" :type="'redeemedForm'" ref="redeemed-form" />
                        </div>
                    </div>
                </Form>
            </div>
            <div class="modal_footer">
                <button :disabled="loader" class="btn cancel_btn" @click="closeModel">Cancel</button>
                <button :disabled="loader" type="button" class="btn save_btn" @click="handleSubmit"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Saving' : 'Save' }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'
    import { Form } from 'vee-validate'

    const NotificationSetting = defineAsyncComponent(() => import('@/pages/admin-setting/components/reward/NotificationSetting'))

    export default {
        name: 'Reward Notification',

        data() {
            return {
                tab: 'pointsAwarded',
                form: {
                    awarded_status: 0,
                    redeemed_status: 0,
                },
                awardedForm: {
                    awarded_subject: '',
                    awarded_email: '',
                    awarded_notification: 3,
                    awarded_sms: '',
                    is_reward_logo: 0,
                    reward_email_logo: '',
                    reward_image: '',
                    reward_show_email_logo: 0,
                    is_reward_signature: 0,
                    reward_signature_id: 0,
                },
                redeemedForm: {
                    redeemed_subject: '',
                    redeemed_email: '',
                    redeemed_notification: 3,
                    redeemed_sms: '',
                    is_redeemed_logo: 0,
                    redeemed_email_logo: '',
                    redeemed_image: '',
                    redeemed_show_email_logo: 0,
                    is_redeemed_signature: 0,
                    redeemed_signature_id: 0,
                },
                loadNotification: false,
            }
        },

        props: {
            modelValue: Boolean,
        },

        components: {
            Form,
            NotificationSetting,
        },

        computed: mapState({
            reward: state => state.rewardModule.reward,
            loader: state => state.rewardModule.rewardLoader,
        }),

        watch: {
            reward (reward) {
                const vm = this;

                if (reward) {
                    vm.resetForm();
                }
            },

            modelValue (val) {
                if (val) {
                    const vm = this;
                    document.body.classList.add('modal-open');

                    vm.loadNotification = false;

                    setTimeout(function () {
                        vm.loadNotification = true;
                    }, 1000);

                    if (!Object.keys(vm.reward).length) {
                        vm.getReward();
                    }
                }else{
                    document.body.classList.remove('modal-open');
                }
            },
        },

        mounted() {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                getReward: 'rewardModule/getReward',
                updateRewardNotification: 'rewardModule/updateRewardNotification',
            }),

            closeModel () {
                const vm = this;

                vm.$emit('update:modelValue', false)
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    awarded_status: vm.reward && vm.reward.awarded_status ? vm.reward.awarded_status : 0,
                    redeemed_status: vm.reward && vm.reward.redeemed_status ? vm.reward.redeemed_status : 0,
                };

                vm.awardedForm = {
                    awarded_subject: vm.reward && vm.reward.awarded_subject ? vm.reward.awarded_subject : '',
                    awarded_email: vm.reward && vm.reward.awarded_email ? vm.reward.awarded_email : '',
                    awarded_notification: vm.reward && vm.reward.awarded_notification ? vm.reward.awarded_notification : 3,
                    awarded_sms: vm.reward && vm.reward.awarded_sms ? vm.reward.awarded_sms : '',
                    is_reward_logo: vm.reward && vm.reward.is_reward_logo ? vm.reward.is_reward_logo : 0,
                    reward_email_logo: vm.reward && vm.reward.reward_email_logo ? vm.reward.reward_email_logo : '',
                    reward_image: vm.reward && vm.reward.reward_image ? vm.reward.reward_image : '',
                    reward_show_email_logo: vm.reward && vm.reward.reward_show_email_logo ? vm.reward.reward_show_email_logo : 0,
                    is_reward_signature: vm.reward && vm.reward.is_reward_signature ? vm.reward.is_reward_signature : 0,
                    reward_signature_id: vm.reward && vm.reward.reward_signature_id ? vm.reward.reward_signature_id : 0,
                    sms_media: vm.reward && vm.reward.reward_sms_media ? vm.reward.reward_sms_media : [],
                };

                vm.redeemedForm = {
                    redeemed_subject: vm.reward && vm.reward.redeemed_subject ? vm.reward.redeemed_subject : '',
                    redeemed_email: vm.reward && vm.reward.redeemed_email ? vm.reward.redeemed_email : '',
                    redeemed_notification: vm.reward && vm.reward.redeemed_notification ? vm.reward.redeemed_notification : 3,
                    redeemed_sms: vm.reward && vm.reward.redeemed_sms ? vm.reward.redeemed_sms : '',
                    is_redeemed_logo: vm.reward && vm.reward.is_redeemed_logo ? vm.reward.is_redeemed_logo : 0,
                    redeemed_email_logo: vm.reward && vm.reward.redeemed_email_logo ? vm.reward.redeemed_email_logo : '',
                    redeemed_image: vm.reward && vm.reward.redeemed_image ? vm.reward.redeemed_image : '',
                    redeemed_show_email_logo: vm.reward && vm.reward.redeemed_show_email_logo ? vm.reward.redeemed_show_email_logo : 0,
                    is_redeemed_signature: vm.reward && vm.reward.is_redeemed_signature ? vm.reward.is_redeemed_signature : 0,
                    redeemed_signature_id: vm.reward && vm.reward.redeemed_signature_id ? vm.reward.redeemed_signature_id : 0,
                    sms_media: vm.reward && vm.reward.redeemed_sms_media ? vm.reward.redeemed_sms_media : [],
                };
            },

            handleSubmit () {
                const vm = this;

                vm.$refs['notificationForm'].validate().then((result) => {
                    if (result.valid) {
                        vm.handleRewardsNotification();
                    }
                });
            },

            handleRewardsNotification () {
                const vm = this;

                const awardedForm  = vm.$refs['awarded-form'];
                const redeemedForm = vm.$refs['redeemed-form'];

                vm.form.awarded_subject          = awardedForm.emailContent.subject;
                vm.form.awarded_email            = awardedForm.emailContent.email;
                vm.form.awarded_notification     = awardedForm.notif_method;
                vm.form.awarded_sms              = awardedForm.delivery_sms;
                vm.form.is_reward_logo           = awardedForm.emailContent.is_email_logo;
                vm.form.reward_image             = awardedForm.emailContent.email_logo;
                vm.form.reward_show_email_logo   = awardedForm.emailContent.show_email_logo;
                vm.form.is_reward_signature      = awardedForm.emailContent.is_signature;
                vm.form.reward_signature_id      = awardedForm.emailContent.signature_id;
                vm.form.redeemed_subject         = redeemedForm.emailContent.subject;
                vm.form.redeemed_email           = redeemedForm.emailContent.email;
                vm.form.redeemed_notification    = redeemedForm.notif_method;
                vm.form.redeemed_sms             = redeemedForm.delivery_sms;
                vm.form.is_redeemed_logo         = redeemedForm.emailContent.is_email_logo;
                vm.form.redeemed_image           = redeemedForm.emailContent.email_logo;
                vm.form.redeemed_show_email_logo = redeemedForm.emailContent.show_email_logo;
                vm.form.is_redeemed_signature    = redeemedForm.emailContent.is_signature;
                vm.form['content-8']             = awardedForm.emailContent.email;
                vm.form['content-9']             = redeemedForm.emailContent.email;
                vm.form.points_name              = vm.reward.points_name;
                vm.form.points_currency          = vm.reward.points_currency;
                vm.form.redeemed_signature_id    = redeemedForm.emailContent.signature_id;
                vm.form.closeModel               = vm.closeModel;
                vm.form.reward_sms_media         = awardedForm.sms_media;
                vm.form.redeemed_sms_media       = redeemedForm.sms_media;

                vm.updateRewardNotification(vm.form);
            }
        }
    }
</script>
